import { Link } from "gatsby";
import styled from "styled-components";
import { Color } from "../../layout/GlobalStyles/Color";
import { Font } from "../../layout/GlobalStyles/Font";
import { Dimensions } from "../../layout/GlobalStyles/Dimensions";

export const Primary = styled(Link)`
    font-family: ${Font.Poppins};
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    border-radius: ${Dimensions.GridUnit / 2}px;
    background-color: ${Color.Primary100};
    display: inline-block;
    margin-left: 10px;
    line-height: 1.32;
    padding: 8px 16px;
    color: ${Color.Neutral0};
    transition: background-color 100ms ease;
    box-shadow: 0px 0px 8px ${Color.Primary50};

    :hover {
        background-color: ${Color.Primary110};
    }
`;

export const Secondary = styled(Link)`
    font-family: ${Font.Poppins};
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    border-radius: ${Dimensions.GridUnit / 2}px;
    background-color: ${Color.Neutral100};
    display: inline-block;
    margin-left: 10px;
    line-height: 1.32;
    padding: 8px 16px;
    color: ${Color.Neutral0};
    transition: background-color 100ms ease;
    box-shadow: 0px 0px 8px ${Color.Neutral50};

    :hover {
        background-color: ${Color.Neutral90};
    }
`;
